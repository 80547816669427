<template>
  <div v-if="dialogVisible">
    <el-dialog
      v-loading="loading"
      :visible="dialogVisible"
      width="85%"
      :before-close="handleClose"
      style="padding: 0; font-family: PingFangTC-Regular"
      :modal-append-to-body="false"
      :append-to-body="true"
    >
      <!-- 一、問卷資訊統計 -->
      <div class="calc-item">。問卷資訊統計</div>
      <el-divider></el-divider>
      <div class="qu-block">
        <el-row
          style="
            font-size: 16px;
            display: flex;
            flex-direction: column;
            width: 45%;
          "
        >
          <el-col>
            <span>問卷ID</span>
            <span>{{ getQuestionnaire.id }}</span>
          </el-col>
          <el-col>
            <span>問卷標題</span>
            <span>{{ getQuestionnaire.title }}</span>
          </el-col>
          <el-col>
            <span>回收佔比</span>
            <span>
              {{ getQuestionnaire.finish_count }} /
              {{ getQuestionnaire.recover_copy_limit }}
            </span>
          </el-col>
        </el-row>

        <el-row
          style="
            font-size: 16px;
            display: flex;
            flex-direction: column;
            width: 45%;
          "
        >
          <el-col>
            <span>刊登日</span>
            <span>
              {{ wrapDate(getQuestionnaire.available_start_datetime) }}
            </span>
          </el-col>
          <el-col>
            <span>結束日 (原因)</span>
            <span>
              {{ wrapDate(getQuestionnaire.actual_stop_datetime) }}
              <div style="color: #1d63ab; margin-left: 10px">
                {{ getQuestionnaire.status_description }}
              </div>
            </span>
          </el-col>
        </el-row>
      </div>

      <!-- 二、受眾條件 -->
      <div>
        <span class="calc-item">。受眾條件</span>
      </div>
      <el-divider></el-divider>
      <el-collapse>
        <el-collapse-item title="" name="1" style="border-bottom: none">
          <div style="display: flex; justify-content: space-between">
            <div style="width: 47%">
              <div>
                <span class="calc-item" style="color: #646464; padding: 12px">
                  正式條件列表
                </span>
                <span style="color: #909399">*藍色欄位為pro進階篩選條件</span>
              </div>
              <el-divider></el-divider>
            </div>

            <div style="width: 47%">
              <div>
                <span class="calc-item" style="color: #646464; padding: 12px">
                  排除條件列表
                </span>
                <span style="color: #909399">*藍色欄位為pro進階篩選條件</span>
              </div>
              <el-divider></el-divider>
            </div>
          </div>

          <div class="qu-block qu-block-audience">
            <!-- 正向條件for迴圈 -->
            <el-row
              style="
                font-size: 16px;
                display: flex;
                flex-direction: column;
                width: 45%;
              "
            >
              <div
                v-for="(item, index) in positive_filter_list"
                :key="item.groupName"
              >
                <el-col v-if="index !== 0" class="fix-margin">
                  <span>{{ item.groupConditionRadio ? '或' : '且' }}</span>
                  <span></span>
                </el-col>
                <el-col>
                  <span :class="{ 'blue-font': item.is_charge }">
                    {{ item.groupName }}
                  </span>
                  <span>
                    <ul
                      :class="{ 'blue-font': item.is_charge }"
                      style="list-style: none; padding: 0; margin: 0"
                    >
                      <li
                        v-for="(content, index) in item.groupItem"
                        :key="content.value.label"
                        style="list-style: none"
                      >
                        {{ content.value.label }}
                        <span v-if="index !== item.groupItem.length - 1">
                          或
                        </span>
                      </li>
                    </ul>
                  </span>
                </el-col>
              </div>
              <el-col v-if="positive_filter_list.length === 0">
                <span>未設定</span>
              </el-col>
            </el-row>
            <!--  排除條件for迴圈 -->
            <el-row
              style="
                font-size: 16px;
                display: flex;
                flex-direction: column;
                width: 45%;
              "
            >
              <div
                v-for="(item, index) in exclude_filter_list"
                :key="item.groupName"
              >
                <el-col v-if="index !== 0" class="fix-margin">
                  <span>{{ item.groupConditionRadio ? '或' : '且' }}</span>
                  <span></span>
                </el-col>
                <el-col>
                  <span :class="{ 'blue-font': item.is_charge }">
                    {{ item.groupName }}
                  </span>
                  <span>
                    <ul
                      :class="{ 'blue-font': item.is_charge }"
                      style="list-style: none; padding: 0; margin: 0"
                    >
                      <li
                        v-for="(content, index) in item.groupItem"
                        :key="content.value.label"
                        style="list-style: none"
                      >
                        {{ content.value.label }}
                        <span v-if="index !== item.groupItem.length - 1">
                          或
                        </span>
                      </li>
                    </ul>
                  </span>
                </el-col>
              </div>
              <el-col v-if="exclude_filter_list.length === 0">
                <span style="padding-left: 25px">未設定</span>
              </el-col>
            </el-row>
          </div>
        </el-collapse-item>
      </el-collapse>

      <!-- 二、下載檔案 -->
      <div>
        <span class="calc-item" style="margin: 20px 0">。下載檔案</span>
      </div>
      <el-divider></el-divider>
      <div class="qu-block" style="justify-content: flex-start">
        <div style="margin-top: 20px">
          <span style="margin-right: 50px; font-size: 16px">分析報表</span>
          <el-button
            style="margin-right: 15px; font-weight: 300"
            type="primary"
            @click="handleDownload(0)"
          >
            下載csv檔（選項文字）
          </el-button>
          <el-button
            style="font-weight: 300"
            type="primary"
            @click="handleDownload(1)"
          >
            下載csv檔（選項序號）
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import questionnaireTableColumn from '@/mixin/questionnaireTableColumn';
import { mapGetters } from 'vuex';
import { wrapDate } from '@/utils/wrapDate';
import { getReport } from '@/api/questionnaire';

export default {
  name: 'AnalysisChartDialog',
  mixins: [questionnaireTableColumn],
  props: ['dialogVisible'],
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getQuestionnaire', 'loading']),
    positive_filter_list() {
      let list = [];
      if (this.dialogVisible) {
        list =
          this.getQuestionnaire.positive_filter_setting !== ''
            ? JSON.parse(this.getQuestionnaire.positive_filter_setting)
            : [];
      }
      return list;
    },
    exclude_filter_list() {
      let list = [];
      if (this.dialogVisible) {
        list =
          this.getQuestionnaire.exclude_filter_setting !== ''
            ? JSON.parse(this.getQuestionnaire.exclude_filter_setting)
            : [];
      }
      return list;
    },
  },

  methods: {
    wrapDate,
    handleClose(done) {
      this.$emit('update:dialogVisible', false);
      done();
    },
    async handleDownload(type) {
      try {
        const res = await getReport({
          questionnaire_id: this.getQuestionnaire.id,
          type,
        });
        this.download(res, type);
      } catch (error) {
        if (error.message) this.$message.error(error.message);
      }
    },
    download(data, type) {
      let url = URL.createObjectURL(new Blob([data]));
      let link = document.createElement('a');
      link.style.display = 'none';
      link.href = url;
      link.setAttribute(
        'download',
        `問卷ID-${this.getQuestionnaire.id}_${this.getQuestionnaire.title}_${
          type === 1 ? '序號' : '文字'
        }.csv`
      );
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(link.href); // 釋放URL 物件
      document.body.removeChild(link);
    },
  },
};
</script>
<style lang="scss" scoped>
.blue-font {
  color: #1c63aa !important;
}

.el-divider--horizontal {
  margin: 10px 0;
}

.el-col {
  margin: 10px 0;
  display: flex;
  font-size: 16px;
}

.el-col.fix-margin {
  margin: 0;
}

.qu-block {
  display: flex;
  margin: 20px 0 30px 0;
  justify-content: center;
  width: 95%;
  margin: 0 auto;
  margin-bottom: 30px;
  justify-content: space-around;
}

.qu-block-audience {
  display: flex;
  width: 95%;
  justify-content: space-around;
  margin: 0 auto;
  margin-bottom: 30px;
}

.el-col > :first-child {
  color: #909399;
  padding: 5px 20px 5px 0;
  width: 225px;
  display: flex;
  justify-content: flex-end;
}

.el-col > :last-child {
  color: #000;
  padding: 5px 0 5px 5px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.calc-item {
  font-size: 18px;
  font-weight: 600;
  padding-right: 10px;
}

.title-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.total {
  margin: 10px 0;
  padding: 10px 0;
  display: grid;
  grid-template-columns: 1fr 130px;
  font-size: 16px;
  background-color: #efefef;
}

.el-collapse {
  border-top: none;
  border-bottom: none;
}

/deep/.el-collapse-item__header {
  border-bottom: 1px solid transparent !important;
}

/deep/.el-collapse-item__wrap {
  border-bottom: 1px solid transparent !important;
}
</style>
