<template>
  <div>
    <el-button
      type="primary"
      icon="el-icon-plus"
      style="float: right"
      @click="$router.push('/create-questionnaire')"
    >
      新增問卷
    </el-button>
    <el-table
      :data="getList"
      style="width: 100%"
      empty-text="目前沒有資料，請點擊「新增問卷」按鈕。"
      :default-sort="{ prop: 'id', order: 'descending' }"
    >
      <el-table-column
        v-for="col in getColumns"
        :key="col.dbName"
        :prop="col.dbName"
        :label="col.name"
        :sortable="col.sortable"
        :width="col.width"
        :show-overflow-tooltip="col['show-overflow-tooltip']"
      >
        <template slot-scope="{ row }">
          <!-- 申請狀態 -->
          <div
            v-if="col.dbName === 'status'"
            :style="{ color: colorPair[row.status] }"
          >
            {{ row['status_description'] }}
            <el-popover
              v-if="row.status === 3"
              placement="right"
              :visible-arrow="false"
              trigger="hover"
              popper-class="popoverBg"
              width="200"
            >
              <i
                slot="reference"
                class="el-icon-warning"
                style="font-size: 16px; cursor: help"
              ></i>
              <div>{{ row.reject_reason }}</div>
            </el-popover>
          </div>
          <!-- 刊登日 與 更新時間-->
          <div
            v-else-if="
              col.dbName === 'available_start_datetime' ||
              col.dbName === 'update_datetime'
            "
            v-html="wrapDate(row[col.dbName], 'break')"
          ></div>
          <!-- 結束日 -->
          <div
            v-else-if="
              col.name !== '結束日(原因)' &&
              col.dbName === 'available_end_datetime'
            "
            v-html="wrapDate(row[col.dbName], 'break')"
          ></div>
          <!-- 結束日(原因) -->
          <div v-else-if="col.name === '結束日(原因)' && row.id">
            {{ row[col.dbName].split(' ')[0] }}
            <br />
            {{
              row[col.dbName].slice(0, row[col.dbName].length - 3).split(' ')[1]
            }}
            <div style="color: #1d63ab">
              {{ row['status_description'] }}
              <el-popover
                v-if="row.finish_reason"
                placement="right"
                :visible-arrow="false"
                trigger="hover"
                popper-class="popoverBg"
                width="200"
              >
                <i
                  slot="reference"
                  class="el-icon-warning"
                  style="font-size: 16px; cursor: help"
                ></i>
                <div>{{ row.finish_reason }}</div>
              </el-popover>
            </div>
          </div>
          <!-- 問卷標題 -->
          <div
            v-else-if="col.dbName === 'title'"
            style="
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            "
          >
            {{ row[col.dbName] }}
          </div>
          <!-- 再次推播 -->
          <div v-else-if="col.isButton">
            <el-button
              v-show="row.id"
              type="primary"
              size="small"
              @click="pushNotification(row)"
            >
              再次推播
            </el-button>
          </div>
          <!-- 回收佔比 -->
          <div v-else-if="col.name === '回收佔比' && row.id">
            {{ row[col.dbName] }} / {{ row.recover_copy_limit }}
          </div>
          <!-- 問卷ID/提醒推播數 -->
          <div v-else>{{ row[col.dbName] }}</div>
        </template>
      </el-table-column>
      <!-- 問卷列表的按鈕 -->
      <el-table-column width="120px">
        <template slot-scope="{ row }">
          <el-dropdown
            v-show="row.id"
            size="medium"
            trigger="click"
            split-button
            @command="rowActions"
            @click="excuteFunc(row)"
            class="btn-action"
          >
            {{ getOperations.main.name }}
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(op, index) in getOperations.other"
                :key="index"
                :command="{ command: op.func, data: row }"
              >
                {{ op.name }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>

    <component
      :dialogVisible.sync="showDialog"
      :is="dialogType"
      :retake.sync="selectRetake"
      :available.sync="selectPushCount"
    ></component>
    <PreviewQuestions></PreviewQuestions>
    <!-- 強制結束彈窗 -->
    <el-dialog
      title="強制結束"
      :visible="outerForceVisible"
      custom-class="force-close-dialog"
      @close="outerForceVisible = false"
    >
      <el-dialog
        title="強制結束確認"
        :visible="innerForceVisible"
        append-to-body
        custom-class="force-close-dialog"
        :show-close="false"
      >
        <p>
          確認強制結束後，若需重新發送本份問卷需至問卷已結束狀態建立副本，並重新設定預算和時間。
        </p>
        <div style="display: flex; justify-content: flex-end">
          <el-button
            size="small"
            @click="
              innerForceVisible = false;
              outerForceVisible = false;
            "
          >
            取消
          </el-button>
          <el-button
            size="small"
            type="danger"
            @click="forceQuestionnaireClose"
          >
            確認
          </el-button>
        </div>
      </el-dialog>
      <p>
        一旦強制結束將停止問卷刊登，系統將於3個工作天內結算扣點返還(僅結算問卷回收費用，首次推播費已使用無法點數返還)。
      </p>
      <div style="display: flex; justify-content: flex-end">
        <el-button size="small" @click="outerForceVisible = false">
          取消
        </el-button>
        <el-button size="small" type="danger" @click="innerForceVisible = true">
          強制結束
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import questionnaireTableColumn from '@/mixin/questionnaireTableColumn';
import SendRemindNotification from '@/components/questionnaireList/dialog/SendRemindNotification';
import CheckCost from '@/components/questionnaireList/dialog/CheckCost';
import ExtendTime from '@/components/questionnaireList/dialog/ExtendTime';
import ViewQuestionnaire from '@/components/questionnaireList/dialog/ViewQuestionnaire';
import AnalysisChart from '@/components/questionnaireList/dialog/AnalysisChart';
import PreviewQuestions from '@/components/questionnaireList/PreviewQuestions';
import { wrapDate } from '@/utils/wrapDate';

export default {
  name: 'QuestionnaireListTable',
  props: {
    currentState: { type: String, default: '1' },
  },
  mixins: [questionnaireTableColumn],
  components: {
    CheckCost,
    ExtendTime,
    ViewQuestionnaire,
    AnalysisChart,
    SendRemindNotification,
    PreviewQuestions,
  },
  data() {
    return {
      previewQuestionnaire: false,

      // 申請狀態文字顏色
      colorPair: {
        0: 'unset',
        1: '#1d63ab',
        2: '#1ca916',
        3: '#f56c6c',
      },
    };
  },
  computed: {
    ...mapGetters(['getList']),
  },
  methods: {
    ...mapMutations(['SET_CURRENT_QUESTIONNAIRE']),
    wrapDate,
    excuteFunc(data) {
      this.SET_CURRENT_QUESTIONNAIRE(data);
      const functionName = this.getOperations.main.func;
      this[`${functionName}`]();
    },
    rowActions(command) {
      // console.log(command.command);
      this.SET_CURRENT_QUESTIONNAIRE(command.data);
      this[`${command.command}`]();
    },
  },
};
</script>

<style lang="scss" scoped>
.svg-icon {
  width: 24px;
  height: 17px;
}
/deep/.force-close-dialog > .el-dialog__body {
  padding-top: 0;
}
/deep/.el-table__body {
  width: 100%;
  // 使表格兼容safari，不错位
  table-layout: fixed !important;
}
</style>
