<template>
  <div v-if="dialogVisible">
    <el-dialog
      v-loading="loading"
      :title="`再次推播設定 - ${getQuestionnaire.title}`"
      :visible="dialogVisible"
      width="550px"
      :before-close="handleClose"
      :modal-append-to-body="false"
      :append-to-body="true"
      style="font-family: PingFangTC-Regular"
    >
      <div style="display: flex; gap: 20px">
        <small>
          * 可選擇推播受眾人數 = 推播問卷受眾人數 - 目前已回收問卷人數
        </small>
        <el-button
          icon="el-icon-refresh"
          size="small"
          type="primary"
          @click="updateData"
          style="margin-left: 15px"
        >
          更新資料
        </el-button>
      </div>

      <el-row :gutter="10" style="margin: 15px 0 30px">
        <el-col :span="8">
          <div>更新時間</div>
          <el-divider></el-divider>
          <div>
            {{ lastUpdate }}
          </div>
        </el-col>

        <el-col :span="10">
          <div>可選擇推播受眾人數</div>
          <el-divider></el-divider>
          <div>{{ available }}</div>
        </el-col>
      </el-row>

      <div style="margin-bottom: 10px">
        <div class="small-title">
          再次推播人數費用
          <small style="font-size: 12px">
            * 再次推播人數總計費用若有小數點則無條件進位
          </small>
        </div>
        <el-row>
          <el-col :span="8">再次推播人數</el-col>
          <el-col :span="2">x</el-col>
          <el-col :span="5">推播服務費</el-col>
          <el-col :span="2">=</el-col>
          <el-col :span="7">總計推播費</el-col>
        </el-row>
        <el-divider></el-divider>
        <el-row>
          <el-col :span="8">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              @submit.native.prevent
            >
              <el-form-item prop="num">
                <el-input
                  type="number"
                  v-model="ruleForm.num"
                  style="width: 100px"
                ></el-input>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="2">x</el-col>
          <el-col :span="5">{{ pushFee }}</el-col>
          <el-col :span="2">=</el-col>
          <el-col :span="7">
            {{ Math.ceil(ruleForm.num * pushFee) || 0 }}
          </el-col>
        </el-row>
      </div>

      <div style="margin-bottom: 20px">
        <div class="small-title">
          再次推播格式
          <el-popover
            placement="right"
            :visible-arrow="false"
            trigger="hover"
            popper-class="popoverBg"
          >
            <img
              slot="reference"
              src="../../../assets/image/icon-info.png"
              width="22"
              style="transform: translateY(5px)"
            />
            <div>
              * 再次推播申請時間若超過當日上午10:00，於隔天上午10:00發送推播。
              <br />
              * 問卷結束日前一天無法申請再次推播。
            </div>
          </el-popover>
        </div>
        <push-notification-block
          :choosed.sync="choosed"
          :title="getQuestionnaire.title"
          :version="version"
        ></push-notification-block>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="sendNotification('ruleForm')">
          確定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import PushNotificationBlock from '@/components/questionnaire/PushNotificationBlock';
import { dateTimeWithSlash } from '@/utils/datetimeFormat';
import questionnaireTableColumn from '@/mixin/questionnaireTableColumn';
import getFee from '@/mixin/getFee';
import getNotificationFormat from '@/mixin/getNotificationFormat';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CheckCostDialog',
  components: { PushNotificationBlock },
  mixins: [questionnaireTableColumn, getFee, getNotificationFormat],
  props: ['dialogVisible', 'available'],
  data() {
    const checkNum = (rule, value, callback) => {
      const regex = /^[1-9][0-9]*$/;
      if (!value) {
        callback(new Error('請填入數字'));
      } else if (value < 0) {
        callback(new Error('數字不可小於1'));
        this.ruleForm.num = 0;
      } else if (value > this.available) {
        callback(new Error('數字須小於可選擇推播受眾人數'));
      } else if (value > 10000) {
        callback(new Error('數字不可大於1萬人'));
      } else if (!regex.test(value)) {
        callback(new Error('請輸入正確的數字'));
      } else {
        callback();
      }
    };

    return {
      lastUpdate: dateTimeWithSlash(new Date()),
      choosed: 0,
      ruleForm: {
        num: 0,
      },
      rules: {
        num: [{ validator: checkNum, trigger: 'blur' }],
      },
    };
  },
  computed: {
    ...mapGetters(['getQuestionnaire', 'loading']),
  },
  methods: {
    ...mapActions(['doGetPushCount', 'doResendPush']),
    handleClose(done) {
      this.$emit('update:dialogVisible', false);
      this.$refs['ruleForm'].resetFields();
      done();
    },
    async sendNotification(formName) {
      const valid = await this.$refs[formName].validate().catch((e) => e);
      if (!valid) return false;

      this.$confirm(
        '確定送出再次推播後，費用即立即計算，並可從成本統計查看明細。',
        '提示',
        {
          type: 'warning',
          confirmButtonText: '確定',
          cancelButtonText: '取消',
        }
      )
        .then(() => {
          const data = {
            questionnaire_id: this.getQuestionnaire.id,
            notification_title: this.version[this.choosed].title,
            notification_content: this.version[this.choosed].content,
            target_count: this.ruleForm.num,
          };
          this.reSendPush(data);
        })
        .catch(() => {});
    },
    async updateData() {
      this.lastUpdate = dateTimeWithSlash(new Date());
      const res = await this.doGetPushCount(this.getQuestionnaire.id);
      this.$emit('update:available', res.count);
    },
    async reSendPush(data) {
      const res = await this.doResendPush(data);
      if (res) {
        this.$emit('update:dialogVisible', false);
        this.$refs['ruleForm'].resetFields();
      } else {
        this.$router.push('/user-profile');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.el-divider--horizontal {
  margin: 5px 0;
}

.small-title {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 15px;
  small {
    font-weight: 400;
  }
}
</style>
