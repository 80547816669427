<template>
  <div v-if="dialogVisible">
    <el-dialog
      v-loading="loading"
      title="成本統計"
      :visible="dialogVisible"
      width="800px"
      :before-close="handleClose"
      :modal-append-to-body="false"
      :append-to-body="true"
      style="font-family: PingFangTC-Regular"
    >
      <!-- 一、問卷設定 -->
      <div class="calc-item">。問卷設定</div>
      <el-divider></el-divider>
      <el-row style="display: flex; margin-bottom: 30px; font-size: 16px">
        <el-col style="flex-direction: column">
          <div style="justify-content: flex-start; padding: 5px 20px 5px 10px">
            回收份數上限
          </div>
          <div style="padding: 5px 0 5px 10px">
            {{ getQuestionnaire.recover_copy_limit }}
          </div>
        </el-col>
        <el-col style="flex-direction: column">
          <div style="justify-content: flex-start; padding: 5px 20px 5px 10px">
            預估費用
          </div>
          <div style="padding: 5px 0 5px 10px">
            {{ getQuestionnaire.estimated_cost }}
          </div>
        </el-col>
      </el-row>

      <!-- 二、成本細項 -->
      <div>
        <span class="calc-item">。成本細項</span>
        <span>計算結果可能因網路而無法正常更新，最終仍是以實際份數結算。</span>
      </div>
      <el-divider></el-divider>

      <!-- 1. 問卷費用 -->
      <div class="title-layout">
        <div style="font-size: 16px; font-weight: 600; margin: 15px 0">
          問卷費用
        </div>
        <el-button
          v-if="$route.query.status === '2'"
          icon="el-icon-refresh"
          size="small"
          type="primary"
          @click="updateData"
        >
          更新資料
        </el-button>
      </div>
      <div class="qu-block" style="display: flex">
        <el-row
          style="
            font-size: 16px;
            display: flex;
            flex-direction: column;
            width: 45%;
          "
        >
          <el-col>
            <span>更新時間</span>
            <span v-html="getLastUpdate"></span>
          </el-col>
          <el-col>
            <span>回收服務費</span>
            <span>{{ recoverFee }} 點/人</span>
          </el-col>
          <el-col>
            <span>首次推播人數</span>
            <span>{{ getQuestionnaire.deliver_count }} 人</span>
          </el-col>
          <el-col>
            <span>pro進階篩選</span>
            <span>{{ getQuestionnaire.pro_filter || 0 }} 個</span>
          </el-col>
        </el-row>

        <el-row
          style="
            font-size: 16px;
            display: flex;
            flex-direction: column;
            width: 45%;
          "
        >
          <el-col>
            <span>回收問卷份數</span>
            <span style="color: #1d63ab">
              {{ retake }}
              人
            </span>
          </el-col>
          <el-col>
            <span>填答回饋點數</span>
            <span>{{ getQuestionnaire.feedback_point }} 點/人</span>
          </el-col>
          <el-col>
            <span>推播服務費</span>
            <span>{{ pushFee }} 點/人</span>
          </el-col>
          <el-col>
            <span>pro進階服務費</span>
            <span>{{ proFilterFee }} 點/人</span>
          </el-col>
        </el-row>
      </div>
      <div class="total">
        <div style="text-align: end; padding-right: 50px">累積費用</div>
        <div style="font-weight: 600">{{ getAccumulation }} 點</div>
      </div>

      <div style="margin: 10px 0 30px; display: flex">
        <div style="flex-shrink: 0">計算公式：</div>
        <div>
          [填答回饋點數 + 回收服務費 + pro進階篩選(個) x pro進階服務費] x
          回收份數上限 + 首次推播人數 x 推播服務費 = 預估費用 ≤ 預算上限
        </div>
      </div>

      <!-- 2. 再次發送推播費用 -->
      <div style="font-size: 16px; font-weight: 600; margin: 15px 0">
        再次發送推播費用
      </div>
      <el-table
        :data="getPushList"
        style="width: 100%"
        empty-text="此份問卷無再次推播申請"
      >
        <el-table-column label="推播時間">
          <template slot-scope="{ row }">
            {{ wrapDate(row.schedule_push_time) || '設定中...' }}
          </template>
        </el-table-column>
        <el-table-column prop="target_count" label="人數"></el-table-column>
        <el-table-column label="推播服務費">{{ pushFee }}</el-table-column>
        <el-table-column prop="charge_fee" label="累積費用"></el-table-column>
      </el-table>

      <div class="total">
        <div style="text-align: end; padding-right: 50px">合併總費用</div>
        <div style="font-weight: 600">{{ getTotal }} 點</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import questionnaireTableColumn from '@/mixin/questionnaireTableColumn';
import getFee from '@/mixin/getFee';
import { dateTimeWithSlash } from '@/utils/datetimeFormat';
import { mapActions, mapGetters } from 'vuex';
import { wrapDate } from '@/utils/wrapDate';

export default {
  name: 'CheckCostDialog',
  mixins: [questionnaireTableColumn, getFee],
  props: ['dialogVisible', 'retake'],
  data() {
    return {
      lastUpdate: new Date(),
    };
  },
  computed: {
    ...mapGetters(['getQuestionnaire', 'getPushList', 'loading']),
    getLastUpdate() {
      const date = dateTimeWithSlash(this.lastUpdate);
      return date;
    },
    getAccumulation() {
      const question_charge = this.recoverFee;
      const send_charge = this.pushFee;
      const filter_charge = this.proFilterFee;
      const feedback_point = this.getQuestionnaire.feedback_point;
      const pro_filter = this.getQuestionnaire.pro_filter || 0;
      const deliver_count = this.getQuestionnaire.deliver_count;

      const calc =
        (feedback_point + question_charge + pro_filter * filter_charge) *
          this.retake +
        deliver_count * send_charge;
      return Math.ceil(calc);
    },
    getTotal() {
      const remindTotal =
        this.getPushList.length > 0
          ? this.getPushList
              .map((item) => {
                return item.charge_fee;
              })
              .reduce((total, e) => total + e)
          : 0;
      return Math.ceil(remindTotal + this.getAccumulation);
    },
  },

  methods: {
    ...mapActions(['doGetRetake']),
    wrapDate,
    handleClose(done) {
      this.$emit('update:dialogVisible', false);
      done();
    },
    async updateData() {
      this.lastUpdate = new Date();
      const res = await this.doGetRetake(this.getQuestionnaire.id);
      this.$emit('update:retake', res.count);
    },
  },
};
</script>
<style lang="scss" scoped>
.el-divider--horizontal {
  margin: 10px 0;
}

.calc-item {
  font-size: 18px;
  font-weight: 600;
  padding-right: 10px;
}

.title-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.total {
  margin: 10px 0;
  padding: 10px 0;
  display: grid;
  grid-template-columns: 1fr 130px;
  font-size: 16px;
  background-color: #e8effa;
}

.el-col {
  margin: 8px 0;
  display: flex;
  font-size: 16px;
}

.el-col > :first-child {
  color: #909399;
  padding: 5px 20px 5px 0;
  width: 225px;
  display: flex;
  justify-content: flex-end;
}

.el-col > :last-child {
  color: #000;
  padding: 5px 0 5px 5px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
</style>
