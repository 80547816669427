var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-button',{staticStyle:{"float":"right"},attrs:{"type":"primary","icon":"el-icon-plus"},on:{"click":function($event){return _vm.$router.push('/create-questionnaire')}}},[_vm._v(" 新增問卷 ")]),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.getList,"empty-text":"目前沒有資料，請點擊「新增問卷」按鈕。","default-sort":{ prop: 'id', order: 'descending' }}},[_vm._l((_vm.getColumns),function(col){return _c('el-table-column',{key:col.dbName,attrs:{"prop":col.dbName,"label":col.name,"sortable":col.sortable,"width":col.width,"show-overflow-tooltip":col['show-overflow-tooltip']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(col.dbName === 'status')?_c('div',{style:({ color: _vm.colorPair[row.status] })},[_vm._v(" "+_vm._s(row['status_description'])+" "),(row.status === 3)?_c('el-popover',{attrs:{"placement":"right","visible-arrow":false,"trigger":"hover","popper-class":"popoverBg","width":"200"}},[_c('i',{staticClass:"el-icon-warning",staticStyle:{"font-size":"16px","cursor":"help"},attrs:{"slot":"reference"},slot:"reference"}),_c('div',[_vm._v(_vm._s(row.reject_reason))])]):_vm._e()],1):(
            col.dbName === 'available_start_datetime' ||
            col.dbName === 'update_datetime'
          )?_c('div',{domProps:{"innerHTML":_vm._s(_vm.wrapDate(row[col.dbName], 'break'))}}):(
            col.name !== '結束日(原因)' &&
            col.dbName === 'available_end_datetime'
          )?_c('div',{domProps:{"innerHTML":_vm._s(_vm.wrapDate(row[col.dbName], 'break'))}}):(col.name === '結束日(原因)' && row.id)?_c('div',[_vm._v(" "+_vm._s(row[col.dbName].split(' ')[0])+" "),_c('br'),_vm._v(" "+_vm._s(row[col.dbName].slice(0, row[col.dbName].length - 3).split(' ')[1])+" "),_c('div',{staticStyle:{"color":"#1d63ab"}},[_vm._v(" "+_vm._s(row['status_description'])+" "),(row.finish_reason)?_c('el-popover',{attrs:{"placement":"right","visible-arrow":false,"trigger":"hover","popper-class":"popoverBg","width":"200"}},[_c('i',{staticClass:"el-icon-warning",staticStyle:{"font-size":"16px","cursor":"help"},attrs:{"slot":"reference"},slot:"reference"}),_c('div',[_vm._v(_vm._s(row.finish_reason))])]):_vm._e()],1)]):(col.dbName === 'title')?_c('div',{staticStyle:{"overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(row[col.dbName])+" ")]):(col.isButton)?_c('div',[_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(row.id),expression:"row.id"}],attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.pushNotification(row)}}},[_vm._v(" 再次推播 ")])],1):(col.name === '回收佔比' && row.id)?_c('div',[_vm._v(" "+_vm._s(row[col.dbName])+" / "+_vm._s(row.recover_copy_limit)+" ")]):_c('div',[_vm._v(_vm._s(row[col.dbName]))])]}}],null,true)})}),_c('el-table-column',{attrs:{"width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('el-dropdown',{directives:[{name:"show",rawName:"v-show",value:(row.id),expression:"row.id"}],staticClass:"btn-action",attrs:{"size":"medium","trigger":"click","split-button":""},on:{"command":_vm.rowActions,"click":function($event){return _vm.excuteFunc(row)}}},[_vm._v(" "+_vm._s(_vm.getOperations.main.name)+" "),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.getOperations.other),function(op,index){return _c('el-dropdown-item',{key:index,attrs:{"command":{ command: op.func, data: row }}},[_vm._v(" "+_vm._s(op.name)+" ")])}),1)],1)]}}])})],2),_c(_vm.dialogType,{tag:"component",attrs:{"dialogVisible":_vm.showDialog,"retake":_vm.selectRetake,"available":_vm.selectPushCount},on:{"update:dialogVisible":function($event){_vm.showDialog=$event},"update:dialog-visible":function($event){_vm.showDialog=$event},"update:retake":function($event){_vm.selectRetake=$event},"update:available":function($event){_vm.selectPushCount=$event}}}),_c('PreviewQuestions'),_c('el-dialog',{attrs:{"title":"強制結束","visible":_vm.outerForceVisible,"custom-class":"force-close-dialog"},on:{"close":function($event){_vm.outerForceVisible = false}}},[_c('el-dialog',{attrs:{"title":"強制結束確認","visible":_vm.innerForceVisible,"append-to-body":"","custom-class":"force-close-dialog","show-close":false}},[_c('p',[_vm._v(" 確認強制結束後，若需重新發送本份問卷需至問卷已結束狀態建立副本，並重新設定預算和時間。 ")]),_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end"}},[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){_vm.innerForceVisible = false;
            _vm.outerForceVisible = false;}}},[_vm._v(" 取消 ")]),_c('el-button',{attrs:{"size":"small","type":"danger"},on:{"click":_vm.forceQuestionnaireClose}},[_vm._v(" 確認 ")])],1)]),_c('p',[_vm._v(" 一旦強制結束將停止問卷刊登，系統將於3個工作天內結算扣點返還(僅結算問卷回收費用，首次推播費已使用無法點數返還)。 ")]),_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end"}},[_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){_vm.outerForceVisible = false}}},[_vm._v(" 取消 ")]),_c('el-button',{attrs:{"size":"small","type":"danger"},on:{"click":function($event){_vm.innerForceVisible = true}}},[_vm._v(" 強制結束 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }