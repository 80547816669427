<template>
  <div v-if="dialogVisible">
    <el-dialog
      v-loading="loading"
      :visible="dialogVisible"
      width="85%"
      :before-close="handleClose"
      style="padding: 0; font-family: PingFangTC-Regular"
      :modal-append-to-body="false"
      :append-to-body="true"
    >
      <!-- 一、問卷設定 -->
      <div class="calc-item">。問卷設定</div>
      <el-divider></el-divider>
      <div class="qu-block">
        <el-row
          style="
            font-size: 16px;
            display: flex;
            flex-direction: column;
            width: 45%;
          "
        >
          <el-col>
            <span>問卷標題</span>
            <span>{{ getQuestionnaire.title }}</span>
          </el-col>
          <el-col>
            <span>有效時間</span>
            <span>
              {{ wrapDate(getQuestionnaire.available_start_datetime) }} -
              {{ wrapDate(getQuestionnaire.available_end_datetime) }}
            </span>
          </el-col>
          <el-col v-if="getQuestionnaire.image !== ''">
            <span>問卷進入頁上方圖片</span>
            <el-image :src="getQuestionnaire.image"></el-image>
          </el-col>
          <el-col v-if="getQuestionnaire.thank_image !== ''">
            <span>感謝作答圖片</span>
            <el-image :src="getQuestionnaire.thank_image"></el-image>
          </el-col>
          <el-col>
            <span>首次推播</span>
            <span>
              <div>
                <span
                  v-if="getQuestionnaire.notification_title"
                  style="font-weight: 500"
                >
                  {{ getQuestionnaire.notification_title }}
                </span>
                <span v-else>未設定</span>
                <br />
                <span style="font-size: 15px">
                  {{ getQuestionnaire.notification_content }}
                </span>
              </div>
            </span>
          </el-col>
          <el-col>
            <span>再次推播</span>
            <span>
              <div v-if="getPushList.length > 0">
                <div
                  v-for="(item, index) in getPushList"
                  :key="index"
                  style="margin-bottom: 8px"
                >
                  <span style="font-size: 13px; color: #646464">
                    申請時間：{{ wrapDate(item.create_time) }}
                  </span>
                  <br />
                  <span style="font-weight: 500">
                    {{ item.notification_title }}
                  </span>
                  <br />
                  <span style="font-size: 15px">
                    {{ item.notification_content }}
                  </span>
                </div>
              </div>
              <div v-else>無再次推播申請</div>
            </span>
          </el-col>
        </el-row>

        <el-row
          style="
            font-size: 16px;
            display: flex;
            flex-direction: column;
            width: 45%;
          "
        >
          <el-col>
            <span>問卷版型</span>
            <span>
              {{
                getQuestionnaire.edition_type === 0
                  ? '每頁一題組'
                  : '每頁多題組'
              }}
            </span>
          </el-col>
          <el-col>
            <span>問卷介紹</span>
            <span>{{ getQuestionnaire.content || '未設定' }}</span>
          </el-col>
          <el-col>
            <span>感謝作答語</span>
            <span>{{ getQuestionnaire.thank_word || '未設定' }}</span>
          </el-col>
        </el-row>
      </div>

      <!-- 二、依預算評估份數/依份數評估費用 -->
      <div>
        <span class="calc-item">
          {{
            getQuestionnaire.cost_assessment_type
              ? '。依份數評估費用'
              : '。依預算評估份數'
          }}
        </span>
        <span style="color: #909399">*藍色字為問卷設定自填條件</span>
      </div>
      <el-divider></el-divider>
      <div class="qu-block">
        <el-row
          style="
            font-size: 16px;
            display: flex;
            flex-direction: column;
            width: 45%;
          "
        >
          <el-col v-if="!getQuestionnaire.cost_assessment_type">
            <span>預算上限</span>
            <span class="blue-font">
              {{ getQuestionnaire.budget_limit }} 點
            </span>
          </el-col>
          <el-col>
            <span>回收份數上限</span>
            <span>{{ getQuestionnaire.recover_copy_limit }} 人</span>
          </el-col>
          <el-col>
            <span>首次推播人數</span>
            <span>{{ getQuestionnaire.deliver_count }} 人</span>
          </el-col>
          <el-col>
            <span>pro進階篩選</span>
            <span class="blue-font">
              {{ getQuestionnaire.pro_filter || 0 }} 個
            </span>
          </el-col>
        </el-row>

        <el-row
          style="
            font-size: 16px;
            display: flex;
            flex-direction: column;
            width: 45%;
          "
        >
          <el-col>
            <span>問卷回收服務費</span>
            <span>{{ recoverFee }} 點</span>
          </el-col>
          <el-col>
            <span>填答回饋點數</span>
            <span class="blue-font">
              {{ getQuestionnaire.feedback_point }} 點/人
            </span>
          </el-col>
          <el-col>
            <span>pro進階服務費</span>
            <span>{{ proFilterFee }} 點/人</span>
          </el-col>
          <el-col>
            <span>推播服務費</span>
            <span>{{ pushFee }} 點</span>
          </el-col>
          <el-col>
            <span>預估費用</span>
            <span>{{ getQuestionnaire.estimated_cost }} 點</span>
          </el-col>
        </el-row>
      </div>

      <!-- 三、篩選受眾-正式條件列表  四、篩選受眾-排除條件列表 -->
      <div style="display: flex; justify-content: space-between">
        <div style="width: 47%">
          <div>
            <span class="calc-item">。篩選受眾-正向條件列表</span>
            <span style="color: #909399">*藍色欄位為pro進階篩選條件</span>
          </div>
          <el-divider></el-divider>
        </div>

        <div style="width: 47%">
          <div>
            <span class="calc-item">。篩選受眾-排除條件列表</span>
            <span style="color: #909399">*藍色欄位為pro進階篩選條件</span>
          </div>
          <el-divider></el-divider>
        </div>
      </div>

      <div class="qu-block qu-block-audience">
        <!-- 正向條件for迴圈 -->
        <el-row
          style="
            font-size: 16px;
            display: flex;
            flex-direction: column;
            width: 45%;
          "
        >
          <div
            v-for="(item, index) in positive_filter_list"
            :key="item.groupName"
          >
            <el-col v-if="index !== 0" class="fix-margin">
              <span>{{ item.groupConditionRadio ? '或' : '且' }}</span>
              <span></span>
            </el-col>
            <el-col>
              <span :class="{ 'blue-font': item.is_charge }">
                {{ item.groupName }}
              </span>
              <span>
                <ul
                  :class="{ 'blue-font': item.is_charge }"
                  style="list-style: none; padding: 0; margin: 0"
                >
                  <li
                    v-for="(content, index) in item.groupItem"
                    :key="content.value.label"
                    style="list-style: none"
                  >
                    {{ content.value.label }}
                    <span v-if="index !== item.groupItem.length - 1">或</span>
                  </li>
                </ul>
              </span>
            </el-col>
          </div>
          <el-col v-if="positive_filter_list.length === 0">
            <span>未設定</span>
          </el-col>
        </el-row>
        <!--  排除條件for迴圈 -->
        <el-row
          style="
            font-size: 16px;
            display: flex;
            flex-direction: column;
            width: 45%;
          "
        >
          <div
            v-for="(item, index) in exclude_filter_list"
            :key="item.groupName"
          >
            <el-col v-if="index !== 0" class="fix-margin">
              <span>{{ item.groupConditionRadio ? '或' : '且' }}</span>
              <span></span>
            </el-col>
            <el-col>
              <span :class="{ 'blue-font': item.is_charge }">
                {{ item.groupName }}
              </span>
              <span>
                <ul
                  :class="{ 'blue-font': item.is_charge }"
                  style="list-style: none; padding: 0; margin: 0"
                >
                  <li
                    v-for="(content, index) in item.groupItem"
                    :key="content.value.label"
                    style="list-style: none"
                  >
                    {{ content.value.label }}
                    <span v-if="index !== item.groupItem.length - 1">或</span>
                  </li>
                </ul>
              </span>
            </el-col>
          </div>
          <el-col v-if="exclude_filter_list.length === 0">
            <span style="padding-left: 25px">未設定</span>
          </el-col>
        </el-row>
      </div>

      <!-- 五、題目設定 -->
      <div>
        <span class="calc-item">。題目設定</span>
        <span style="color: #909399">*白底為單題(未設定題組)</span>
      </div>
      <el-divider></el-divider>
      <!-- 題目迴圈 -->
      <div v-for="(item, index) in getDoneQuestionList" :key="index">
        <!-- 題目 -->
        <div v-if="item.question_type === 1">
          <QuestionTemplate
            :questionContent="item"
            :inGroup="false"
          ></QuestionTemplate>
        </div>
        <!-- 題組 -->
        <div v-if="item.question_type === 2">
          <QuestionGroupTemplate :groupContent="item"></QuestionGroupTemplate>
          <div v-for="qu_item in item.question_array" :key="qu_item.id">
            <QuestionTemplate
              :questionContent="qu_item"
              :inGroup="true"
            ></QuestionTemplate>
          </div>
        </div>
      </div>
      <div v-if="getDoneQuestionList.length === 0">
        <p style="color: #000; font-size: 16px; padding-left: 18px">未設定</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import questionnaireTableColumn from '@/mixin/questionnaireTableColumn';
import getFee from '@/mixin/getFee';
import QuestionGroupTemplate from '@/components/questionnaireList/dialog/QuestionGroupTemplate';
import QuestionTemplate from '@/components/questionnaireList/dialog/QuestionTemplate';
import { mapGetters } from 'vuex';
import { wrapDate } from '@/utils/wrapDate';

export default {
  name: 'ViewDialog',
  mixins: [questionnaireTableColumn, getFee],
  props: ['dialogVisible'],
  components: {
    QuestionGroupTemplate,
    QuestionTemplate,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      'getQuestionnaire',
      'getDoneQuestionList',
      'getPushList',
      'loading',
    ]),
    positive_filter_list() {
      let list = [];
      if (this.dialogVisible) {
        list =
          this.getQuestionnaire.positive_filter_setting !== ''
            ? JSON.parse(this.getQuestionnaire.positive_filter_setting)
            : [];
      }
      return list;
    },
    exclude_filter_list() {
      let list = [];
      if (this.dialogVisible) {
        list =
          this.getQuestionnaire.exclude_filter_setting !== ''
            ? JSON.parse(this.getQuestionnaire.exclude_filter_setting)
            : [];
      }
      return list;
    },
  },

  methods: {
    wrapDate,
    handleClose(done) {
      this.$emit('update:dialogVisible', false);
      done();
    },
  },
};
</script>
<style lang="scss" scoped>
.blue-font {
  color: #1c63aa !important;
}

/deep/.el-divider--horizontal {
  margin: 10px 0;
}

/deep/.el-col {
  margin: 10px 0;
  display: flex;
  font-size: 16px;
}

/deep/.el-col.fix-margin {
  margin: 0;
}

/deep/.qu-block {
  display: flex;
  margin: 20px 0 30px 0;
  justify-content: center;
  width: 95%;
  margin: 0 auto;
  margin-bottom: 30px;
  justify-content: space-around;
}

/deep/.qu-block-audience {
  display: flex;
  width: 95%;
  justify-content: space-around;
  margin: 0 auto;
  margin-bottom: 30px;
}

/deep/.el-col > :first-child {
  color: #909399;
  padding: 5px 20px 5px 0;
  width: 225px;
  display: flex;
  justify-content: flex-end;
}

/deep/.el-col > :last-child {
  color: #000;
  padding: 5px 0 5px 5px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

/deep/.qu-setting > .el-col > span:first-child {
  width: 175px;
}

/deep/.calc-item {
  font-size: 18px;
  font-weight: 600;
  padding-right: 10px;
}

/deep/.title-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/deep/.total {
  margin: 10px 0;
  padding: 10px 0;
  display: grid;
  grid-template-columns: 1fr 130px;
  font-size: 16px;
  background-color: #efefef;
}

/deep/.qu-group {
  background-color: #d8d8d8;
}

/deep/.qu-in-group {
  background-color: #f2f2f2;
}
</style>
