<template>
  <div v-if="dialogVisible">
    <el-dialog
      v-loading="loading"
      title="延長問卷"
      :visible="dialogVisible"
      width="400px"
      :before-close="handleClose"
      :modal-append-to-body="false"
      :append-to-body="true"
      style="font-family: PingFangTC-Regular"
    >
      <div style="margin-bottom: 20px">
        若選擇延長問卷將延長問卷的有效時間，不過問卷仍可能因為額滿或強制關閉等原因結束喔！
        <br />
        <small>* 問卷有效期間含延長問卷總時間不得超過90天</small>
      </div>
      <el-form ref="form" :model="form" label-width="80px" :rules="rules">
        <el-form-item label="結束時間" prop="datetime">
          <el-date-picker
            v-model="form.datetime"
            type="date"
            placeholder="請選擇延長日期"
            :default-value="getQuestionnaire.available_end_datetime"
            :picker-options="pickerOptions"
            format="yyyy/MM/dd"
            value-format="yyyy/MM/dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleClose">取消</el-button>
          <el-button type="primary" @click="handleExtend">延長問卷</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import questionnaireTableColumn from '@/mixin/questionnaireTableColumn';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ExtendTime',
  mixins: [questionnaireTableColumn],
  props: ['dialogVisible'],
  data() {
    return {
      form: {
        datetime: '',
      },
      rules: {
        datetime: [
          { required: true, message: '請選擇有效時間', trigger: 'blur' },
        ],
      },
      specificDate: [],
      pickerOptions: {
        disabledDate: this.disabledDate,
      },
    };
  },
  computed: {
    ...mapGetters(['getQuestionnaire', 'loading']),
  },
  created() {
    this.renderSpecificDate();
  },
  methods: {
    ...mapActions(['doExtendTime']),
    handleClose() {
      this.$emit('update:dialogVisible', false);
      this.$refs['form'].resetFields();
    },
    async handleExtend() {
      const valid = await this.$refs['form'].validate().catch((e) => e);
      if (!valid) return false;
      const data = {
        questionnaire_id: this.getQuestionnaire.id,
        extend_datetime: this.form.datetime + ' 10:00:00',
      };
      const res = await this.doExtendTime(data);
      if (res) {
        this.$emit('update:dialogVisible', false);
        this.$refs['form'].resetFields();
      }
    },
    disabledDate(time) {
      const min = new Date(this.getQuestionnaire.available_end_datetime);
      const max = new Date(
        this.getQuestionnaire.available_start_datetime
      ).addDays(89);
      if (time < min || time > max) {
        return true;
      }
      for (let i = 0; i < this.specificDate.length; i++) {
        if (time.getTime() == new Date(this.specificDate[i]).getTime()) {
          return true;
        }
      }
    },
    renderSpecificDate() {
      const nowDay = new Date(this.getQuestionnaire.available_end_datetime);
      let year = nowDay.getFullYear();
      let month = nowDay.getMonth() + 1;
      let day = nowDay.getDate();
      let date_arr = [];
      if (month % 2 !== 0 && day < 25) {
        date_arr.push(`${year}-${month}-25 00:00:00`);
      }
      for (let i = 0; i < 4; i++) {
        let nextMonth = month + 1 > 12 ? 1 : month + 1;
        if (nextMonth < month) year++;
        if (nextMonth === 1 || nextMonth % 2 !== 0) {
          date_arr.push(`${year}-${nextMonth}-25 00:00:00`);
        }
        month = nextMonth;
      }
      console.log(date_arr);
      this.specificDate = date_arr;
    },
  },
};
</script>
