<template>
  <div
    :class="inGroup ? 'qu-block qu-in-group' : 'qu-block'"
    style="
      border-bottom-style: dashed;
      border-bottom-color: #d8d8d8;
      margin-bottom: 0;
      padding: 15px 0;
    "
  >
    <el-row
      class="qu-setting"
      style="font-size: 16px; display: flex; flex-direction: column; width: 95%"
    >
      <!-- 題目title -->
      <el-col>
        <span>
          <!-- <span>{{ this.questionList.indexOf(questionContent.id) + 1 }}.</span> -->
          {{ questionContent.type_description }}
        </span>
        <div>
          <span style="width: 350px">
            {{ questionContent.title }}
            <span v-if="questionContent.is_required">(必填)</span>
          </span>
        </div>
      </el-col>
      <!-- 圖片 -->
      <el-col v-if="questionContent.image">
        <span>圖片</span>
        <div>
          <el-image
            :src="questionContent.image"
            style="width: 300px; height: 150px"
          ></el-image>
        </div>
      </el-col>
      <!-- 影片網址 -->
      <el-col v-if="questionContent.type === 9">
        <span>影片網址</span>
        <el-link :href="questionContent.video_link" target="_blank">
          {{ questionContent.video_link }}
        </el-link>
      </el-col>
      <!-- 選項迴圈 -->
      <el-col v-if="questionContent.option_array.length > 0">
        <span>選項</span>
        <ul
          style="
            list-style: upper-latin;
            transform: translateX(22px);
            display: flex;
            flex-direction: column;
          "
        >
          <li
            v-for="(option, index) in questionContent.option_array"
            :key="index"
          >
            <div style="display: flex">
              <div>
                <p style="width: 350px; margin: 0">
                  {{ option.title }}
                </p>
                <el-image
                  v-if="option.image !== ''"
                  :src="option.image"
                  style="width: 300px; height: 150px; margin-top: 8px"
                ></el-image>
              </div>
              <p
                v-if="option.jump_to_show_name !== ''"
                style="margin: 0 20px 0 10px; color: #909399"
              >
                跳題至：{{ option.jump_to_show_name }}
              </p>
            </div>
          </li>
        </ul>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  name: 'QuestionTemplate',
  props: {
    questionContent: { type: Object },
    inGroup: { type: Boolean },
  },
  computed: {
    ...mapGetters(['getDoneQuestionList']),
    questionList() {
      //處理題號：把所有題目id放進一個array裡
      let list = [];
      this.getDoneQuestionList.forEach((el) => {
        if (el.question_type === 1) {
          list.push(el.id);
        } else {
          el.question_array.forEach((el) => {
            list.push(el.id);
          });
        }
      });
      return list;
    },
  },
};
</script>
