<template>
  <div class="qu-block qu-group" style="margin-bottom: 0">
    <el-row
      class="qu-setting"
      style="font-size: 16px; display: flex; flex-direction: column; width: 95%"
    >
      <el-col>
        <span>題組名</span>
        <span>{{ groupContent.name }}</span>
      </el-col>
      <el-col>
        <span>說明</span>
        <span>
          {{ groupContent.description }}
        </span>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  name: 'QuestionGroupTemplate',
  props: ['groupContent'],
};
</script>
