/**
 * 因為不同的 tab 會顯示不同的 column 跟可執行的 action，所以統一在這邊做管理
 *
 * hasNotStarted：未開始
 * underImplementation：刊登中
 * expired：已結束
 */

import {
  deleteQuestionnair,
  forceStopQuestionnaire,
  cloneQuestionnaire,
} from '@/api/questionnaire';
import { mapGetters, mapActions, mapMutations } from 'vuex';

const hasNotStarted = {
  columns: [
    {
      name: '問卷ID',
      dbName: 'id',
      sortable: true,
      width: '90px',
    },
    {
      name: '申請狀態',
      dbName: 'status',
      sortable: true,
      width: '120px',
    },
    {
      name: '刊登日',
      dbName: 'available_start_datetime',
      sortable: true,
      width: '120px',
    },
    {
      name: '結束日',
      dbName: 'available_end_datetime',
      sortable: true,
      width: '120px',
    },
    {
      name: '問卷標題',
      dbName: 'title',
      'show-overflow-tooltip': true,
    },
    {
      name: '更新時間',
      dbName: 'update_datetime',
      sortable: true,
      width: '120px',
    },
  ],
  operations: {
    main: { name: '編輯', func: 'edit' },
    other: [
      { name: '檢視', func: 'view' },
      { name: '預覽', func: 'preview' },
      { name: '建立副本', func: 'duplicate' },
      { name: '刪除', func: 'delete' },
    ],
  },
};

const underImplementation = {
  columns: [
    {
      name: '問卷ID',
      dbName: 'id',
      sortable: true,
      width: '90px',
    },
    {
      name: '刊登日',
      dbName: 'available_start_datetime',
      sortable: true,
      width: '120px',
    },

    {
      name: '結束日',
      dbName: 'available_end_datetime',
      sortable: true,
      width: '120px',
    },
    {
      name: '問卷標題',
      dbName: 'title',
      'show-overflow-tooltip': true,
    },
    {
      name: '回收佔比',
      dbName: 'finish_count',
    },
    {
      name: '發送提醒推播',
      isButton: true,
      action: 'push',
    },
    {
      name: '提醒推播數',
      dbName: 'deliver_count',
    },
  ],
  operations: {
    main: { name: '檢視', func: 'view' },
    other: [
      { name: '預覽', func: 'preview' },
      { name: '成本統計', func: 'costCalculate' },
      { name: '延長問卷', func: 'extendTime' },
      { name: '建立副本', func: 'duplicate' },
      { name: '強制結束', func: 'forceClose' },
      { name: '分析報表', func: 'checkDashboard' },
    ],
  },
};

const expired = {
  columns: [
    {
      name: '問卷ID',
      dbName: 'id',
      sortable: true,
      width: '90px',
    },
    {
      name: '刊登日',
      dbName: 'available_start_datetime',
      sortable: true,
      width: '120px',
    },
    {
      name: '結束日(原因)',
      dbName: 'actual_stop_datetime',
      sortable: true,
      width: '150px',
    },
    {
      name: '問卷標題',
      dbName: 'title',
      'show-overflow-tooltip': true,
    },
    {
      name: '回收佔比',
      dbName: 'finish_count',
    },
    {
      name: '提醒推播數',
      dbName: 'deliver_count',
    },
    // {
    //   name: '帳單狀態',
    //   dbName: 'check_status',
    // },
  ],
  operations: {
    main: { name: '檢視', func: 'view' },
    other: [
      { name: '建立副本', func: 'duplicate' },
      { name: '成本統計', func: 'costCalculate' },
      { name: '分析報表', func: 'checkDashboard' },
    ],
  },
};

const questionnaireTableColumn = {
  data() {
    return {
      dialogType: '',
      showDialog: false,
      outerForceVisible: false,
      innerForceVisible: false,
      selectRetake: 0,
      selectPushCount: 0,
    };
  },
  computed: {
    ...mapGetters(['getQuestionnaire']),
    getColumns() {
      switch (this.currentState) {
        case '1':
          return hasNotStarted.columns;
        case '2':
          return underImplementation.columns;
        case '3':
          return expired.columns;
      }
    },
    getOperations() {
      switch (this.currentState) {
        case '1':
          return hasNotStarted.operations;
        case '2':
          return underImplementation.operations;
        case '3':
          return expired.operations;
      }
    },
  },
  methods: {
    ...mapMutations(['SET_CURRENT_QUESTIONNAIRE']),
    ...mapActions([
      'toggleActiveTab',
      'fetchList',
      'fetchAllQuestions',
      'doGetRetake',
      'doGetPushCount',
      'doGetPushList',
    ]),

    //編輯問卷
    async edit() {
      const notAllowEditStatus = [1, 2];

      if (notAllowEditStatus.includes(this.getQuestionnaire.status)) {
        this.$alert(
          `問卷${this.getQuestionnaire.status_description}無法編輯`,
          '禁止編輯',
          {
            type: 'error',
            cancelButtonText: '確定',
          }
        ).catch((e) => e);
      } else {
        this.toggleActiveTab('1');
        this.$router.push(
          `/create-questionnaire?id=${this.getQuestionnaire.id}`
        );
      }
    },

    //建立副本
    async duplicate() {
      try {
        const res = await cloneQuestionnaire(this.getQuestionnaire.id);
        this.$message.success(res.message);
        await this.fetchList({ status: '1' });
        const query = { status: 1 };
        this.$router.push({ query }).catch(() => {});
        this.$emit('update:currentState', '1');
      } catch (error) {
        if (error.message) this.$message.error(error.message);
      }
    },

    //成本統計
    async costCalculate() {
      const res = await this.doGetRetake(this.getQuestionnaire.id);
      this.selectRetake = res.count;
      this.doGetPushList(this.getQuestionnaire.id);
      this.dialogType = 'CheckCost';
      this.showDialog = true;
    },

    //延長問卷
    extendTime() {
      const end_day = new Date(
        this.getQuestionnaire.available_start_datetime
      ).addDays(89);
      if (end_day <= new Date(this.getQuestionnaire.available_end_datetime)) {
        this.$alert(`問卷有效期限已滿90天`, '無法延長問卷', {
          type: 'error',
          cancelButtonText: '確定',
        }).catch((e) => e);
      } else {
        this.dialogType = 'ExtendTime';
        this.showDialog = true;
      }
    },

    //檢視問卷
    view() {
      this.dialogType = 'ViewQuestionnaire';
      this.showDialog = true;
      this.fetchAllQuestions(this.getQuestionnaire.id);
      this.doGetPushList(this.getQuestionnaire.id);
    },

    //報表分析
    checkDashboard() {
      this.dialogType = 'AnalysisChart';
      this.showDialog = true;
    },

    //刪除問卷
    async delete() {
      try {
        const reply = await this.$confirm(
          `確定要刪除問卷${this.getQuestionnaire.id}:${this.getQuestionnaire.title}？<br/>一旦刪除將無法復原該問卷資料。`,
          '刪除問卷',
          {
            confirmButtonText: '刪除',
            cancelButtonText: '取消',
            confirmButtonClass: 'el-button--danger',
            dangerouslyUseHTMLString: true,
          }
        );

        if (reply === 'confirm') {
          await deleteQuestionnair(this.getQuestionnaire.id);
          await this.fetchList({ status: '1' });
          this.$message.success(
            `問卷${this.getQuestionnaire.id}:${this.getQuestionnaire.title}已刪除成功`
          );
        }
      } catch (error) {
        if (error.message) this.$message.error(error.message);
      }
    },

    //強制結束
    forceClose() {
      this.outerForceVisible = true;
    },
    async forceQuestionnaireClose() {
      try {
        await forceStopQuestionnaire(this.getQuestionnaire.id);
        await this.fetchList({ status: '2' });
        this.$message.success(
          `問卷${this.getQuestionnaire.id}:${this.getQuestionnaire.title}已強制結束，可至「已結束」切頁查看`
        );
        this.outerForceVisible = false;
        this.innerForceVisible = false;
      } catch (error) {
        if (error.message) this.$message.error(error.message);
        this.outerForceVisible = false;
        this.innerForceVisible = false;
      }
    },
    //再次推播
    async pushNotification(data) {
      this.SET_CURRENT_QUESTIONNAIRE(data);
      const endDateTime = new Date(data.available_end_datetime);
      const date = new Date();
      const dateLimit = date.addDays(1);

      if (dateLimit.getTime() > endDateTime.getTime()) {
        // 已經是結束前一天，不可發送提醒推播
        this.$alert(
          '問卷即將結束無法再次推播，請先延長問卷後再申請。',
          '無法推播',
          {
            type: 'error',
            confirmButtonText: '確定',
          }
        );
      } else {
        const res = await this.doGetPushCount(this.getQuestionnaire.id);
        this.selectPushCount = res.count;
        this.dialogType = 'SendRemindNotification';
        this.showDialog = true;
      }
    },
    //預覽問卷
    preview() {
      this.$router.push({
        path: this.$route.path,
        query: { ...this.$route.query, dialog: 'PreviewQuestions' },
      });
    },
  },
};

export default questionnaireTableColumn;
