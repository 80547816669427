<template>
  <el-tabs
    type="border-card"
    v-model="currentState"
    @tab-click="handleTabClick"
    style="font-family: PingFangTC-Regular"
  >
    <el-tab-pane
      v-for="item in typeList"
      :key="item.status"
      :label="item.label"
      :lazy="true"
      :name="item.status"
    >
      <questionnaire-list-table
        v-if="isGetData"
        :current-state.sync="currentState"
      ></questionnaire-list-table>
      <!-- <el-pagination
        v-if="isGetData"
        layout="prev, pager, next"
        :total="getListCount"
        :page-size="getListPageSize"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :hide-on-single-page="true"
      ></el-pagination> -->
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import QuestionnaireListTable from '@/components/questionnaireList/QuestionnaireListTable';
export default {
  name: 'questionnaire-list',
  components: { QuestionnaireListTable },
  data() {
    return {
      typeList: [
        {
          label: '未開始',
          status: '1',
        },
        {
          label: '刊登中',
          status: '2',
        },
        {
          label: '已結束',
          status: '3',
        },
      ],
      currentState: '1',
      // currentPage: 1,
      isGetData: false,
    };
  },
  watch: {
    // async $route() {
    //   console.log(this.$route);
    //   if (!this.$route.query.dialog) {
    //     this.isGetData = false;
    //     this.currentState = this.$route.query.status || '1';
    //     // this.currentPage = parseInt(this.$route.query.page) || 1;
    //     // this.doFetchList(this.currentState, this.currentPage);
    //     this.doFetchList(this.currentState);
    //   }
    // },
  },
  created() {
    this.getInitList();
  },
  computed: {
    ...mapGetters(['getList', 'getListCount', 'getListPageSize']),
  },
  methods: {
    ...mapActions(['fetchList']),
    getInitList() {
      if (this.$route.query.status) {
        this.currentState = this.$route.query.status;
        // this.currentPage = parseInt(this.$route.query.page) || 1;
      } else {
        // const query = { status: this.currentState, page: 1 };
        const query = { status: this.currentState };
        this.$router.push({ query }).catch(() => {});
      }
      // this.doFetchList(this.currentState, this.currentPage);
      this.doFetchList(this.currentState);
    },
    handleTabClick() {
      if (this.currentState !== this.$route.query.status) {
        this.isGetData = false;
        // const query = { status: this.currentState, page: 1 };
        const query = { status: this.currentState };
        this.$router.push({ query }).catch(() => {});
        this.doFetchList(this.currentState);
      }
    },
    // handleCurrentChange(page) {
    //   this.isGetData = false;
    //   this.currentPage = page;
    //   const query = { status: this.currentState, page };
    //   this.$router.push({ query }).catch(() => {});
    // },
    async doFetchList(status) {
      await this.fetchList({ status });
      this.isGetData = true;
    },
  },
};
</script>
