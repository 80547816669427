export function wrapDate(date, type) {
  if (date) {
    if (type === 'break') {
      return date
        .slice(0, date.length - 3)
        .split(' ')
        .join('<br />');
    }
    return date.slice(0, date.length - 3);
  }
}
